.Refresh {
  height: 100%;
  padding: 0 1rem;
  margin: 0;
  font-family: inherit;
  font-size: 0.875rem;
  color: #23263b;
  background: linear-gradient(-180deg, #fff, #fcfcfd);
  border: 1px solid #d6d6e7;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgb(35 38 59 / 5%);
  line-height: 2rem;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Refresh:hover {
  background: linear-gradient(-180deg, #fff, #f5f5fa);
}

.Refresh svg {
  transition: transform .5s ease-out;
}

.Refresh:active svg {
  transform: rotate(360deg);
}
